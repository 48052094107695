import React from "react"
import Navigation from "./navigation"
import Footer from "./footer"
import containerStyles from "./container.module.css"

export default({children}) => (
  <div>
    <Navigation/>
    <div className={containerStyles.container}>
        {children}
    </div>
    <Footer />
  </div>
)