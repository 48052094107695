import React from "react"
import { Link, graphql } from "gatsby"

import Container from "../components/container"

// Utilities
import kebabCase from "lodash/kebabCase"

import styles from "./index.module.css"

import { Helmet } from 'react-helmet'
import { withPrefix } from "gatsby"

import SEO from "../components/seo"

export default({data}) => (
    <Container>
      <SEO 
        title="A Slacker’s Guide to Programming" 
        description="My typical workday. 1% development. 99% research on Stackoverflow."
        url="https://terencelucasyap.com"
        image="https://elasticbeanstalk-ap-southeast-1-805366489044.s3-ap-southeast-1.amazonaws.com/images/uploads/2019/01/profile.jpeg"
      />

        <div className={styles.masonryWithColumns} id="masonry-container">
            <div className="gutter-sizer"></div>
            {data.allMarkdownRemark.edges.map(({ node, index }) => (
            <div className={styles.tile} data-name="tile" key={node.id}>
                <Link to={node.fields.slug}>
                    <img
                        src={node.frontmatter.image}
                        alt={node.frontmatter.title} />
                </Link>
                <div className={styles.caption}>
                    <ul className={styles.meta}>
                        {node.frontmatter.tags.map((item, index) => (
                        <li className={styles.category} key={index}>
                            <Link to={`/tags/${kebabCase(item)}/`}>
                                {item}
                                { index < node.frontmatter.tags.length - 1 && <span>, </span>}
                            </Link>
                        </li>
                        ))}
                        <li className={styles.date}>
                            {node.frontmatter.date}
                        </li>
                    </ul>
                    <h2>
                        <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
                    </h2>

                    <p>{node.frontmatter.subheader}</p>
                    {node.frontmatter.html}

                </div>
            </div>
            ))}
        </div>
        <Helmet>
            <script src={withPrefix("/js/packery.pkgd.min.js")} type="text/javascript"></script>
            <script src={withPrefix("/js/imagesloaded.pkgd.min.js")} type="text/javascript"></script>
            <script src={withPrefix('/js/scripts.js')} type="text/javascript"></script>
        </Helmet>
    </Container>
)

export const query = graphql`
    query {
        allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
            edges {
              node {
                frontmatter {
                  title
                  date(fromNow: true)
                  subheader
                  tags
                  image
                }
                fields {
                    slug
                  }
                excerpt
                timeToRead
                html
                id
                rawMarkdownBody
              }
            }
          } 
    }
`